
//    const env_name = "local"
const env_name = "live"
var backurl = ""
if(env_name === "local"){
    backurl = "http://localhost:9374"
}
else{
    // backurl = "https://api.labelm.io" // mainet

    backurl ="https://backend-gruncoin.maticz.in" // testnet
}

export default backurl;