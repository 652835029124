import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateHomeForm } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Web3 from 'web3';
import backurl from '../../lib/config';
import ReactQuill from 'react-quill';

import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class HomeUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record._id,
            Title: this.props.record.Title,
            Heading: this.props.record.Heading,
            Description: this.props.record.Description,
            // file: {},
            errors: {},
            file1 : "",
            file2 : "",
            file3 : "",
            file4 : "",
            file5 : "",
            file6 : "",
            file7 : "",
            file8 : "",
            toast : false

        };
    }
    componentDidMount(props) {
        console.log("data>>>>>>> :")
    }


    componentWillReceiveProps(nextProps) {
        //console.log("next Props>>>>",nextProps)
        console.log("nextProps_homeupdated", nextProps?.record)
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                Identifier: nextProps.record.Identifier,
                Title: nextProps.record.Title,
                Description: nextProps.record.Description,

                Heading1: nextProps.record.Heading1,
                Heading2: nextProps.record.Heading2,
                Heading3: nextProps.record.Heading3,
                Heading4: nextProps.record.Heading4,

                Value1: nextProps.record.Value1,
                Value2: nextProps.record.Value2,
                Value3: nextProps.record.Value3,
                Value4: nextProps.record.Value4,

                SubTitle1: nextProps.record.SubTitle1,
                SubTitle2: nextProps.record.SubTitle2,
                SubTitle3: nextProps.record.SubTitle3,
                SubTitle4: nextProps.record.SubTitle4,
                SubTitle5: nextProps.record.SubTitle5,
                SubTitle6: nextProps.record.SubTitle6,
                SubTitle7: nextProps.record.SubTitle7,

                SubDescription1: nextProps.record.SubDescription1,
                SubDescription2: nextProps.record.SubDescription2,
                SubDescription3: nextProps.record.SubDescription3,
                SubDescription4: nextProps.record.SubDescription4,
                SubDescription5: nextProps.record.SubDescription5,
                SubDescription6: nextProps.record.SubDescription6,
                SubDescription7: nextProps.record.SubDescription7,

                ImageURI1: nextProps.record.ImageURI1,
                ImageURI2: nextProps.record.ImageURI2,
                ImageURI3: nextProps.record.ImageURI3,
                ImageURI4: nextProps.record.ImageURI4,
                ImageURI5: nextProps.record.ImageURI5,
                ImageURI6: nextProps.record.ImageURI6,
                ImageURI7: nextProps.record.ImageURI7,
                ImageURI8: nextProps.record.ImageURI8,

                ImageLink1: nextProps.record.ImageLink1,
                ImageLink2: nextProps.record.ImageLink2,
                ImageLink3: nextProps.record.ImageLink3,
                ImageLink4: nextProps.record.ImageLink4,
                ImageLink5: nextProps.record.ImageLink5,
                ImageLink6: nextProps.record.ImageLink6,
                ImageLink7: nextProps.record.ImageLink7,
                ImageLink8: nextProps.record.ImageLink8,
                
            })
            // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {

                if(this.state.toast){
                    console.log("this.stae.toast", this.state.toast, nextProps.auth.form.data.message)
                    // toast("UPDATE", {
                    //     position: toast.POSITION.TOP_CENTER
                    // });

                    this.setState({ toast : false})
                }
            
        }
        // $('#update-form-modal').modal('hide');
        $('#update-form-modal').modal('hide');
        $('#modal')

    }

    onChange = e => {


        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }

        if (e.target.id === 'Heading1') {
            this.setState({ Heading1: e.target.value });
        }
        if (e.target.id === 'Heading2') {
            this.setState({ Heading2: e.target.value });
        }
        if (e.target.id === 'Heading3') {
            this.setState({ Heading3: e.target.value });
        }
        if (e.target.id === 'Heading4') {
            this.setState({ Heading4: e.target.value });
        }

        if (e.target.id === 'Value1') {
            this.setState({ Value1: e.target.value });
        }
        if (e.target.id === 'Value2') {
            this.setState({ Value2: e.target.value });
        }
        if (e.target.id === 'Value3') {
            this.setState({ Value3: e.target.value });
        }
        if (e.target.id === 'Value4') {
            this.setState({ Value4: e.target.value });
        }

        if (e.target.id === 'SubTitle1') {
            this.setState({ SubTitle1: e.target.value });
        }
        if (e.target.id === 'SubTitle2') {
            this.setState({ SubTitle2: e.target.value });
        }
        if (e.target.id === 'SubTitle3') {
            this.setState({ SubTitle3: e.target.value });
        }
        if (e.target.id === 'SubTitle4') {
            this.setState({ SubTitle4: e.target.value });
        }
        if (e.target.id === 'SubTitle5') {
            this.setState({ SubTitle5: e.target.value });
        }
        if (e.target.id === 'SubTitle6') {
            this.setState({ SubTitle6: e.target.value });
        }
        if (e.target.id === 'SubTitle7') {
            this.setState({ SubTitle7: e.target.value });
        }


        if (e.target.id === 'SubDescription1') {
            this.setState({ SubDescription1: e.target.value });
        }
        if (e.target.id === 'SubDescription2') {
            this.setState({ SubDescription2: e.target.value });
        }
        if (e.target.id === 'SubDescription3') {
            this.setState({ SubDescription3: e.target.value });
        }
        if (e.target.id === 'SubDescription4') {
            this.setState({ SubDescription4: e.target.value });
        }
        if (e.target.id === 'SubDescription5') {
            this.setState({ SubDescription5: e.target.value });
        }
        if (e.target.id === 'SubDescription6') {
            this.setState({ SubDescription6: e.target.value });
        }
        if (e.target.id === 'SubDescription7') {
            this.setState({ SubDescription7: e.target.value });
        }
        
        if (e.target.id === 'ImageLink1') {
            this.setState({ ImageLink1: e.target.value });
        }
        if (e.target.id === 'ImageLink2') {
            this.setState({ ImageLink2 : e.target.value });
        }
        if (e.target.id === 'ImageLink3') {
            this.setState({ ImageLink3 : e.target.value });
        }
        if (e.target.id === 'ImageLink4') {
            this.setState({ ImageLink4: e.target.value });
        }
        if (e.target.id === 'ImageLink5') {
            this.setState({ ImageLink5: e.target.value });
        }
        if (e.target.id === 'ImageLink6') {
            this.setState({ ImageLink6: e.target.value });
        }
        if (e.target.id === 'ImageLink7') {
            this.setState({ ImageLink7: e.target.value });
        }
        if (e.target.id === 'ImageLink8') {
            this.setState({ ImageLink8: e.target.value });
        }
    };
    onFileChange = e => {
        // var name = e.target.value;
        // Update the state
        //    this.setState({ file: e.target.files[0]});
        //    this.setState({ imagepath: name});

        let image = e.target.files[0];
        let fileName = image.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        console.log('extFileeeeeeee', extFile)
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "webp") {
            if (e.target.id === 'ImageURI1') {
                this.setState({ file1: e.target.files[0] });
                this.setState({ ImageURI1: e.target.files[0] });
            }
            if (e.target.id === 'ImageURI2') {
                this.setState({ file2: e.target.files[0] });
                this.setState({ ImageURI2: e.target.files[0] });
            }
            if (e.target.id === 'ImageURI3') {
                this.setState({ file3: e.target.files[0] });
                this.setState({ ImageURI3: e.target.files[0] });
            }
            if (e.target.id === 'ImageURI4') {
                this.setState({ file4: e.target.files[0] });
                this.setState({ ImageURI4: e.target.files[0] });
            }
            if (e.target.id === 'ImageURI5') {
                this.setState({ file5: e.target.files[0] });
                this.setState({ ImageURI5: e.target.files[0] });
            }
            if (e.target.id === 'ImageURI6') {
                this.setState({ file6: e.target.files[0] });
                this.setState({ ImageURI6: e.target.files[0] });
            }
            if (e.target.id === 'ImageURI7') {
                this.setState({ file7: e.target.files[0] });
                this.setState({ ImageURI7: e.target.files[0] });
            }
            if (e.target.id === 'ImageURI8') {
                this.setState({ file8: e.target.files[0] });
                this.setState({ ImageURI8: e.target.files[0] });
            }
        } else {
            toast("Allowed Only JPG, WEBP, PNG, JPEG File", {
                position: toast.POSITION.TOP_CENTER
            });
        }


    };


    handleChange = (value) => {
        this.setState({ SubDescription2:value });       
       }


    onFormUpdate = e => {
        e.preventDefault();
        const updateHomeForm = {
            _id: this.state.id,
            Identifier: this.state.Identifier,
            Title: this.state.Title,
            Description: this.state.Description,

            Heading1: this.state.Heading1,
            Heading2: this.state.Heading2,
            Heading3: this.state.Heading3,
            Heading4: this.state.Heading4,

            Value1: this.state.Value1,
            Value2: this.state.Value2,
            Value3: this.state.Value3,
            // Value4:this.state.Value4,

            SubTitle1: this.state.SubTitle1,
            SubTitle2: this.state.SubTitle2,
            SubTitle3: this.state.SubTitle3,
            SubTitle4: this.state.SubTitle4,
            SubTitle5: this.state.SubTitle5,
            SubTitle6: this.state.SubTitle6,
            SubTitle7: this.state.SubTitle7,

            SubDescription1: this.state.SubDescription1,
            SubDescription2: this.state.SubDescription2,
            SubDescription3: this.state.SubDescription3,
            SubDescription4: this.state.SubDescription4,
            SubDescription5: this.state.SubDescription5,
            SubDescription6: this.state.SubDescription6,
            SubDescription7: this.state.SubDescription7,

            ImageURI1: this.state.ImageURI1,
            ImageURI2: this.state.ImageURI2,
            ImageURI3: this.state.ImageURI3,
            ImageURI4: this.state.ImageURI4,
            ImageURI5: this.state.ImageURI5,
            ImageURI6: this.state.ImageURI6,
            ImageURI7: this.state.ImageURI7,
            ImageURI8: this.state.ImageURI8,

            ImageLink1: this.state.ImageLink1,
            ImageLink2: this.state.ImageLink2,
            ImageLink3: this.state.ImageLink3,
            ImageLink4: this.state.ImageLink4,
            ImageLink5: this.state.ImageLink5,
            ImageLink6: this.state.ImageLink6,
            ImageLink7: this.state.ImageLink7,
            ImageLink8: this.state.ImageLink8,
            // file: this.state.file

        };
        console.log("update_datasgal", updateHomeForm)
        this.setState({ toast : true })
        this.props.updateHomeForm(updateHomeForm);
    };

    onClose = () => {
        this.setState({ file1: "" })
        this.setState({ file2: "" })
        this.setState({ file3: "" })
        this.setState({ file4: "" })
        this.setState({ file5: "" })
        this.setState({ file6: "" })
        this.setState({ file7: "" })
        this.setState({ file8: "" })
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                {console.log("toast_stat", backurl, this.state.toast)}
                <div className="modal fade" id="update-form-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Land</h4>
                                {/* onDismiss={()=> {this.setState({show : false})}} */}
                                {console.log("this.props", this.props)}
                                <button type="button" className="close" onClick={ () => {console.log("enter this"); this.props.onDismiss(); this.onClose()}} >&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="update-form">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="form-update-id"
                                        type="text"
                                        required
                                        className="d-none" />

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses">Title</label>
                                        </div>
                                        {this.state.Title && <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                error={errors.Title}
                                                id="Title"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}
                                            />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>}
                                    </div>
                                    {this.state.Description && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                error={errors.Description}
                                                id="Description"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.Heading1 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Heading1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading1}
                                                error={errors.Heading}
                                                id="Heading1"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.Value1 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Value1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Value1}
                                                error={errors.Heading}
                                                id="Value1"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubTitle1 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">SubTitle1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.SubTitle1}
                                                error={errors.Heading}
                                                id="SubTitle1"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubDescription1 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">SubDescription1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.SubDescription1}
                                                error={errors.Description}
                                                id="SubDescription1"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.ImageURI1 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI1}
                                                error={errors.Heading}
                                                id="ImageURI1"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file1?.name  ?  <> {console.log("entet file")}<img  src={URL.createObjectURL(this.state.file1)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI1 != "" ? <img className="img-fluid uploads_img mt-3" src={ backurl + "/Images/" + this.state.ImageURI1} /> : <></>} </>}</>
                                    </div>
                                    }
                                    {this.state.ImageLink1 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink1}
                                                error={errors.Heading}
                                                id="ImageLink1"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.Heading2 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Heading2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading2}
                                                error={errors.Heading}
                                                id="Heading2"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.Value2 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Value2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Value2}
                                                error={errors.Heading}
                                                id="Value2"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubTitle2 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">SubTitle2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.SubTitle2}
                                                error={errors.Heading}
                                                id="SubTitle2"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubDescription2 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">SubDescription2</label>
                                        </div>
                                        <div className="col-md-9">


                                        <ReactQuill                                               
                                        
                                        onChange={this.handleChange}
                                        value={this.state.SubDescription2}
                                        error={errors.Description}
                                         id="SubDescription2"
                                         theme="snow" 
                                            type="text" 
                                        
                                     />
                                            {/* <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.SubDescription2}
                                                error={errors.Description}
                                                id="SubDescription2"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea> */}
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.ImageURI2 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI2}
                                                error={errors.Heading}
                                                id="ImageURI2"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file2?.name  ?  <> {console.log("entet file")}<img src={URL.createObjectURL(this.state.file2)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI2 != "" ? <img className="img-fluid uploads_img mt-3" src={ backurl + "/Images/" + this.state.ImageURI2} /> : <></>} </>}</>
                                    </div>}
                                    {this.state.ImageLink2 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink2}
                                                error={errors.Heading}
                                                id="ImageLink2"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.Heading3 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Heading2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading3}
                                                error={errors.Heading}
                                                id="Heading3"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.Value3 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Value3</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Value3}
                                                error={errors.Heading}
                                                id="Value3"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubTitle3 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">SubTitle3</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.SubTitle3}
                                                error={errors.Heading}
                                                id="SubTitle3"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubDescription3 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">SubDescription3</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.SubDescription3}
                                                error={errors.Description}
                                                id="SubDescription3"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.ImageURI3 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI3</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI3}
                                                error={errors.Heading}
                                                id="ImageURI3"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file3?.name  ?  <> {console.log("entet file")}<img src={URL.createObjectURL(this.state.file3)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI3 != "" ? <img className="img-fluid uploads_img mt-3" src={ backurl + "/Images/" + this.state.ImageURI3} /> : <></>} </>}</>
                                    </div>}
                                    {this.state.ImageLink3 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink3</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink3}
                                                error={errors.Heading}
                                                id="ImageLink3"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.Heading4 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Heading2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading4}
                                                error={errors.Heading}
                                                id="Heading4"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.Value4 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Value4</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Value4}
                                                error={errors.Heading}
                                                id="Value4"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubTitle4 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">SubTitle4</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.SubTitle4}
                                                error={errors.Heading}
                                                id="SubTitle4"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubDescription4 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">SubDescription4</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.SubDescription4}
                                                error={errors.Description}
                                                id="SubDescription4"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.ImageURI4 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI4</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI4}
                                                error={errors.Heading}
                                                id="ImageURI4"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file4?.name  ?  <> {console.log("entet file")}<img src={URL.createObjectURL(this.state.file4)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI4 != "" ? <img className="img-fluid uploads_img mt-3" src={ backurl + "/Images/" + this.state.ImageURI4} /> : <></>} </>}</>
                                    </div>}
                                    {this.state.ImageLink4 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink4</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink4}
                                                error={errors.Heading}
                                                id="ImageLink4"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubTitle5 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">SubTitle5</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.SubTitle5}
                                                error={errors.Heading}
                                                id="SubTitle5"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubDescription5 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">SubDescription5</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.SubDescription5}
                                                error={errors.Description}
                                                id="SubDescription5"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.ImageURI5 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI5</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI5}
                                                error={errors.Heading}
                                                id="ImageURI5"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file5?.name  ?  <> {console.log("entet file")}<img src={URL.createObjectURL(this.state.file5)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI5 != "" ? <img className="img-fluid uploads_img mt-3" src={backurl + "/Images/" + this.state.ImageURI5} /> : <></>} </>}</>
                                    </div>}
                                    {this.state.ImageLink5 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink5</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink5}
                                                error={errors.Heading}
                                                id="ImageLink5"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubTitle6 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">SubTitle6</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.SubTitle6}
                                                error={errors.Heading}
                                                id="SubTitle6"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubDescription6 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">SubDescription6</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.SubDescription6}
                                                error={errors.Description}
                                                id="SubDescription6"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.ImageURI6 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI6</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI6}
                                                error={errors.Heading}
                                                id="ImageURI6"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file6?.name  ?  <> {console.log("entet file")}<img src={URL.createObjectURL(this.state.file6)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI6 != "" ? <img className="img-fluid uploads_img mt-3" src={ backurl + "/Images/" + this.state.ImageURI6} /> : <></>} </>}</>
                                    </div>}
                                    {this.state.ImageLink6 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink6</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink6}
                                                error={errors.Heading}
                                                id="ImageLink6"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubTitle7 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">SubTitle7</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.SubTitle7}
                                                error={errors.Heading}
                                                id="SubTitle7"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.SubDescription7 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">SubDescription7</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.SubDescription7}
                                                error={errors.Description}
                                                id="SubDescription7"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>}
                                    {this.state.ImageURI7 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI7</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI7}
                                                error={errors.Heading}
                                                id="ImageURI7"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file7?.name  ?  <> {console.log("entet file")}<img src={URL.createObjectURL(this.state.file7)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI7 != "" ? <img className="img-fluid uploads_img mt-3" src={ backurl + "/Images/" + this.state.ImageURI7} /> : <></>} </>}</>
                                    </div>}
                                    {this.state.ImageLink7 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink7</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink7}
                                                error={errors.Heading}
                                                id="ImageLink7"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    {this.state.ImageURI8 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageURI8</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onFileChange}
                                                // value={this.state.ImageURI8}
                                                error={errors.Heading}
                                                id="ImageURI8"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                        <>{this?.state?.file8?.name  ?  <> {console.log("entet file")}<img src={URL.createObjectURL(this.state.file8)} className="img-fluid uploads_img mt-3" /> </> : <> {this.state.ImageURI8 != "" ? <img className="img-fluid uploads_img mt-3" src={backurl + "/Images/" + this.state.ImageURI8} /> : <></>} </>}</>
                                    </div>}
                                    {this.state.ImageLink8 && <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">ImageLink8</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ImageLink8}
                                                error={errors.Heading}
                                                id="ImageLink8"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>}
                                    <div className="row mt-2">
                                        {/* <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div> */}
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"  onClick={ () => {console.log("enter this"); this.props.onDismiss();  this.onClose(); this.setState({ toast : true })}}>Close</button>
                                <a
                                    form="update-form"

                                    className="btn btn-primary" onClick={this.onFormUpdate}>
                                    Update
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

HomeUpdateModal.propTypes = {
    updateHomeForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateHomeForm }
)(withRouter(HomeUpdateModal));
